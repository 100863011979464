<template>
  <div class="buyCouponsHs">
    <div class="card">
      <div class="title">优惠券历史记录</div>
      <div class="tab">
        <div
          @click="handleClick(i + 2)"
          class="tab-item"
          :class="index == i + 2 ? 'active' : ''"
          v-for="(item, i) in tabData"
          :key="i"
        >
          {{ item }}
        </div>
      </div>
      <div class="list" v-if="listData.list && listData.list.length != 0">
        <CouponsI
          :type="type"
          v-for="item in listData.list"
          :key="item.id"
          :data="item"
        ></CouponsI>
      </div>
      <div
        v-else
        style="
          text-align: center;
          line-height: 100px;
          font-size: 14px;
          color: #b0b0b0;
        "
      >
        暂无
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: right">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listData.total"
        :page-size="page_size"
        :current-page="page"
        @current-change="swPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import CouponsI from "@/components/marketing/CouponsI";
import { getMyList } from "@/api/buy.js";
export default {
  components: {
    CouponsI,
  },
  data() {
    return {
      tabData: ["无效", "已使用"],
      index: 2,
      type: "end",
      page: 1,
      page_size: 20,
      listData: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getMyList({
        state: this.index,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    handleClick(i) {
      this.index = i;
      this.page = 1;
      this.getList();
    },
    swPage(e) {
      this.page = e;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.buyCouponsHs {
  padding: 20px;
  .card {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 1px #eee;
    padding: 20px;
    width: 1000px;
    margin-bottom: 30px;
    .title {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }
    .tab {
      display: flex;
      border-bottom: 1px solid #eee;
      .tab-item {
        padding: 10px 30px 14px;
        cursor: pointer;
      }
      .active {
        color: #ee4d2d;
        border-bottom: 2px solid #ee4d2d;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>